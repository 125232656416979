// Declare font here
// @font-face {
//   font-family: "Alaska";
//   src: url("../fonts/alaska.ttf") format("truetype");
//   font-display: swap;
// }

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/roboto-v30-latin-300.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/roboto-v30-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/roboto-v30-latin-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/roboto-v30-latin-700.woff2') format('woff2');
}
