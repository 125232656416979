@use '../abstracts/mixins' as *;
@use '../utilities/container';

.hero {
  --flow-spacer: 4rem;

  @extend .container !optional;
  margin: 0 auto;
  padding-block: 2rem;

  background: var(--clr-white);

  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);

  &__title {
    text-align: center;
  }

  &__icon {
    text-align: center;

    svg {
      height: 100px;
      width: auto;
    }
  }

  &__subtitle {
    --flow-spacer: 1.5rem;
    font-weight: 300;
    text-align: center;
  }

  &__text {
    font-size: var(--fs-500);
    text-align: center;

    margin-inline: auto;
  }
}

@include mq(medium) {
  .hero {
    min-height: 150px;
    padding-block: 5rem;

    &__text {
      max-width: 60ch;
    }

    &__subtitle {
      --flow-spacer: 1rem;
    }
  }
}
