@use '../../utilities/container';

.form {
  @extend .container !optional;

  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;

  &__submit {
    width: max-content;
    margin-inline: auto;
  }

  &:has(input) {
    @media (max-width: 870px) {
      width: 100% !important;
    }
  }
}
