.search {
  background: var(--clr-white);
  padding-block: 4rem;

  border-bottom: 1px solid var(--clr-primary);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);

  &__title {
    text-align: center;
    margin: 0 0 3rem 0;
  }

  &__input {
  }

  &__link-container {
    display: flex;
    gap: 1em;
  }

  &__link {
    display: inline-block;
    font-size: var(--fs-400);
    text-decoration: none;
    border: 1px solid var(--clr-primary);
    border-radius: var(--border-radius);
    padding: 0.5em 1.25em;

    &:hover,
    &:focus-visible {
      border-color: var(--clr-secondary);
      text-decoration: none;
    }
  }
}
