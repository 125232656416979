@use '../abstracts/mixins' as *;

.footer {
  text-align: center;
  background: linear-gradient(
    to bottom,
    var(--clr-glowing-blue),
    var(--clr-white)
  );
  color: var(--clr-black);
  font-size: var(--fs-500);

  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);

  a {
    color: var(--clr-black);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &__text:after {
    content: '';
    background: var(--clr-black);
    height: 2px;
    width: 100%;
    display: block;
    max-width: 330px;
    margin: 40px auto;
  }

  &__menu {
    margin-top: 40px;

    a {
      font-size: var(--fs-400);
    }
  }

  &__menu-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    @include mq(medium) {
      flex-direction: row;
      gap: 2em;
    }
  }

  &__copy {
    font-size: var(--fs-400);
  }

  &__social {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .socialicon {
    height: 2.5rem;
    width: 2.5rem;
  }
}
