@use '../abstracts/mixins' as *;

.container {
  --spacer: 3rem;
  width: min(100% - 2rem, 1200px);
  margin: var(--spacer) auto;
  padding: 0;
}

@include mq(big) {
  .container {
    --spacer: 5rem;
    padding: 0 9.375rem;
  }
}
