@use '../../abstracts/mixins' as *;
.cards {
  &.gradient-light-blue-dark-blue {
    background: linear-gradient(
      to top,
      var(--clr-glowing-blue),
      var(--clr-lighter-blue)
    );
  }

  &__header {
    margin-bottom: 2.5rem;

    @include mq(medium) {
      margin-bottom: 5rem;
    }

    &.container {
      padding-top: 2.5rem;
      @include mq(medium) {
        padding: 5rem;
      }
    }
  }
  &__footer {
    &.container {
      padding-bottom: 2.5rem;
      @include mq(medium) {
        padding-bottom: 5rem;
      }
    }
  }
  &__title {
    margin-bottom: 2.5rem;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    gap: 20px;
    @include mq(intermedium) {
      flex-direction: row;
    }
  }

  .card {
    padding: 40px;
    background-color: var(--clr-white);
    border-radius: var(--border-radius, 15px);
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;

    @include mq(big) {
      margin-bottom: 5rem;
      width: 100%;
      max-width: 400px;
    }

    &__iconwrapper {
      text-align: center;
    }

    &__icon {
      margin-top: 1.25rem;
      margin-bottom: 2.5rem;
    }

    &__title {
      margin-bottom: 2.5rem;
    }

    &__text {
      margin-bottom: 2.5rem;
    }

    &__link {
      margin-top: auto;
      text-decoration: none;
      font-weight: 600;
      &::before {
        content: '\2794  ';
        position: relative;
        top: -2px;
        font-size: 1.5625rem;
      }
    }
  }
  .roundImg .card {
    background-color: transparent;
    text-align: left;
    font-size: var(--fs-450);
    &__icon {
      background: #fff;
      display: flex;
      width: 240px;
      border-radius: 120px;
      margin: 0 auto;
      height: 240px;
      justify-content: center;
      align-items: center;
    }
    &__title {
      margin-top: 3.125rem;
    }
  }
}
