.links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.875rem;
  text-align: center;
  padding: 0;

  &__tile {
    border: 3px solid var(--clr-primary);
    padding: 0.5em 2em 0.9em;
    border-radius: var(--border-radius, 15px);
    text-decoration: none;
    transition: box-shadow ease 0.1s;
    position: relative;
    overflow: hidden;

    &:hover {
      text-decoration: none;
      box-shadow: var(--box-shadow);
    }
    .title {
      font-size: 1.875rem;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .icon,
  .icon svg {
    width: auto;
    height: 4.875rem;
  }
}

.links__tile-tag {
  position: absolute;
  z-index: 1;
  rotate: 37deg;
  background: var(--clr-warm-sun);
  color: var(--clr-primary);
  padding: 0.15rem;
  width: 200%;
  translate: -34%;
  font-size: 1rem;
  font-weight: 700;
  top: 7%;
  left: 56%;
  text-align: center;
}
