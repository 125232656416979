@use '../abstracts' as *;

:root {
  --section-spacing: 3.5rem;
  @include mq(medium) {
    --section-spacing: 7rem;
  }
  @each $color, $value in $colors {
    --clr-#{$color}: #{$value};
  }

  @each $screen-size, $property in $type-scale {
    @if $screen-size == small {
      @each $prop, $value in $property {
        --fs-#{$prop}: #{$value};
      }
    } @else {
      @include mq(small) {
        @each $prop, $value in $property {
          --fs-#{$prop}: #{$value};
        }
      }
    }
  }

  --border-radius: 15px;
  --box-shadow: 0 8px 24px 0 rgba(19, 64, 148, 0.28);
  --transition-all: all ease 0.1s;
}
