.richtext {
  a {
    word-break: break-word;
  }

  .text-center {
    text-align: center;
    display: inline-block;
    width: 100%;
  }

  .text-center a {
    text-decoration: none;
    --border-color: var(--clr-white);
    --color: var(--clr-white);
    color: var(--color);
    background-color: var(--bg-color);

    border: 2px solid var(--border-color);
    padding: 0.5em 2em;
    border-radius: var(--border-radius, 15px);
    cursor: pointer;
    transition: box-shadow 0.2s ease;

    &:focus-visible {
      outline: 2px solid var(--border-color);
      outline-offset: 2px;
    }

    &:hover {
      box-shadow: var(--button-shadow-soft);
    }
  }

  .richtext-image.full-width {
    margin-inline: auto;
  }

  .button.richtext-button {
    display: flex;
    max-width: fit-content;

    a {
      text-decoration: none;
    }
  }
}

.button--primary.richtext-button {
  display: flex;
  max-width: fit-content;

  a {
    color: var(--clr-white);
    text-decoration: none;
  }
}
