.streamfield.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem 3.5rem;

  .button {
    text-decoration: none;

    &--primary:hover {
      color: var(--clr-white);
    }
  }
}
