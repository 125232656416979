.slider {
  .splide {
    .splide__arrow {
      background-color: var(--clr-primary);

      svg {
        fill: white;

        &:focus-visible {
          outline: 2px solid var(--clr-focus-1);
          outline-offset: 3px;
        }
      }
    }

    &__pagination__page {
      background-color: var(--clr-primary);
      opacity: 0.4;
      height: 12px;
      width: 12px;

      &.is-active {
        opacity: 1;
        background-color: var(--clr-primary);
      }
    }
  }

  &__image {
    height: auto !important;
    width: 100% !important;
    // max-width: 200px;
  }
}
