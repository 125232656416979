@use 'breakpoints' as *;

@mixin mq($key) {

  $size: map-get($breakpoints, $key);

  @media only screen and (min-width: $size) {
    @content;
  }
}
