.not-found {
  height: 100%;
  display: grid;
  place-content: center;
  place-items: center;
  gap: 2rem;

  padding-block: 2rem;

  &__icon {
    max-height: 200px;
    max-width: 100%;
  }

  &__link {
    font-size: var(--fs-450);
    text-decoration: none;
  }
}
