body {
  font-size: var(--fs-500);
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--clr-text-color);
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
  font-stretch: 100%;
  line-height: 1.1;
  color: var(--clr-primary);
}

h1 {
  color: var(--clr-primary);
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1 {
  font-size: var(--fs-800);
  font-weight: 600;
}

h2,
.h2 {
  --flow-spacer: 1.5em;
  font-size: var(--fs-700);
  font-weight: 600;
}

h3,
.h3 {
  --flow-spacer: 1.5em;
  font-size: var(--fs-600);
  font-weight: 400;
}

h4,
.h4 {
  font-size: var(--fs-500);
  font-weight: 400;
}

small,
.text-small {
  font-size: var(--fs-300);
}

strong {
  font-weight: 600;
}

p {
  hyphens: auto;
}

a {
  color: var(--clr-primary);

  &:hover,
  &:focus {
    color: var(--clr-primary);
    text-decoration: underline;
  }
}

.text-center {
  text-align: center;
}

// change selection color bc of blue container bg
::selection {
  background-color: var(--clr-warm-sun);
}
