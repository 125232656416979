@use '../abstracts/mixins' as *;

.banner-image {
  --height: calc(70vh + 1em);
  --max-height: 300px;
  --_factor: 2;

  margin-block-end: calc(min(var(--height), 301px) * -1 / var(--_factor));
  isolation: isolate;
  position: relative;
  z-index: -1;
  height: var(--height);
  max-height: var(--max-height);
  max-width: 1920px;
  margin-inline: auto;

  &::after {
    content: '';
    position: absolute;
    background-color: var(--clr-white);
    height: 1em;
    width: 100%;
    bottom: 0;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
}

@include mq(small) {
  .banner-image {
    --max-height: 400px;
  }
}

@include mq(medium) {
  .banner-image {
    --max-height: 700px;
    --_factor: 1.5;
  }
}
