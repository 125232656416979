.message {
  border-radius: var(--border-radius);
  padding: 5rem 2.5rem !important;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background: var(--bg-color);
  color: var(--color);

  &--error {
    --bg-color: hsl(0 50% 75%);
    --color: hsl(0 50% 10%);
    text-shadow: none;
  }

  &--success {
    --bg-color: linear-gradient(
      to right,
      var(--clr-clear-blue-green),
      var(--clr-green-energy)
    );
    --color: var(--clr-white);
  }

  &--warning {
    --bg-color: 60 35% 70%;
    --color: 60 35% 20%;
  }

  &--information {
    --bg-color: linear-gradient(
      to right,
      var(--clr-glowing-blue),
      var(--clr-clear-blue-green)
    );
    --color: var(--clr-white);
  }

  &__title {
    font-size: var(--fs-500);
    color: var(--clr-white);
    line-height: 1;
  }

  a {
    color: var(--color);
  }
}
