@use '../../abstracts/mixins' as *;

.cta {
  min-height: 34rem;
  width: 100%;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-end;

  max-width: 1920px;
  margin-inline: auto;
  @include mq(medium) {
    min-height: 45.25rem;
  }

  .container {
    z-index: 1;
    padding: 0;

    .box {
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      padding: 1.25rem;
      max-width: 40rem;
      background: rgba(255, 255, 255, 0.9);

      @include mq(medium) {
        padding: 2.5rem;
      }
    }
  }
  .button {
    margin-top: 2.5rem;
    display: inline-block;
    text-decoration: none;
    text-align: center;

    &:hover {
      color: var(--clr-white);
    }
  }

  &--small {
    width: min(100% - 2rem, 1200px);
    padding: 1rem;
    border-radius: var(--border-radius);
    overflow: hidden;

    .container {
      margin-block: 0;
      margin-inline: 0;
      width: 100%;
    }

    .button {
      display: block;
      padding: 0.5em 1.25em;
      margin-inline: auto;

      @include mq(small) {
        display: inline-block;
        padding: 0.5em 2em;
      }
    }
    @include mq(medium) {
      padding: 2rem;
      min-height: 600px;
    }
  }
}
