.radio-group {
  &__root {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  &__item-container {
    display: flex;
    align-items: center;
  }

  &__item {
    all: unset;
    font-size: 1rem;
    background-color: white;
    border: 3px solid hsl(var(--clr-neutral-800));
    width: 1.5em;
    height: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 10px;
    border-radius: 100%;
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      box-shadow: 0 0 0 2px rgb(0 0 0 / 0.4);
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px hsl(var(--clr-neutral-800));
    }
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: hsl(var(--clr-neutral-800));
    }
  }

  &__label {
    color: hsl(var(--clr-neutral-800));
    padding: 0 0 0 0.5em;
    font-size: clamp(1.125rem, 4vw, 1.5rem);
    line-height: 1;
    cursor: pointer;
    font-weight: 300;
  }

  &__error {
    color: red;
    border-radius: 15px;
    background: hsl(var(--clr-neutral-800));
    font-size: clamp(1rem, 4vw, 1.25rem);
    padding: 1em;
    display: flex;
    align-items: center;
    gap: 1em;
    margin: 1em 0;

    span {
      margin: 0.35em 0 0;
    }
  }
}
