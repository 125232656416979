@use '../../abstracts/mixins' as *;

$arrow-icon-size: 64px;

.bonuswelt {
  margin: 0 20px;
  @include mq(small) {
    margin: 0 auto;
    max-width: 1228px;
  }
  &__before_slide {
    text-align: center;
  }

  &__splide {
    max-width: calc(100vw - 40px);
    @include mq(small) {
      max-width: calc(100vw);
    }
  }

  .splide {
    display: block;

    @include mq(small) {
      //width: calc(100% - 128px);
      padding: 0 $arrow-icon-size;
    }

    ul {
      align-items: stretch;
      flex-grow: 0;
    }
  }

  .splide__arrow {
    background-color: transparent;
    width: $arrow-icon-size;
    height: $arrow-icon-size;
    svg {
      fill: none;
      stroke: var(--clr-primary);
      stroke-miterlimit: 10;
      stroke-width: 2;
      stroke-linejoin: round;
      stroke-linecap: round;
      width: $arrow-icon-size;
      height: $arrow-icon-size;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
  .bonuswelt_company {
    height: 100%;
    max-width: calc(100vw - 40px);
    width: 100%;
  }
  &__button {
    //margin: auto;
  }
  .splide__pagination {
    position: initial;

    li button {
      &.is-active {
        background-color: var(--clr-primary);
      }
      border: 1px solid var(--clr-primary);
      background-color: var(--clr-white);
      transform: scale(1.4);
      opacity: 1;
      height: 10px;
      width: 10px;
      margin-right: 10px;
    }
  }
  &__button_uebersicht {
    text-align: center;
    margin-top: 30px;
  }
}
