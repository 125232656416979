@use '../abstracts/mixins' as *;

.nav {
  background: var(--clr-primary);
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  transition: height 0.2s ease;
  height: 0;
  top: 100%;

  @include mq(small) {
    align-items: center;
  }

  &--open {
    height: 100vh;
    opacity: 0.95;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);

    @include mq(small) {
      height: 90px;
    }
  }

  &__list {
    display: flex;
    gap: 3em;
    position: absolute;
    flex-direction: column;
    align-items: center;
    padding-top: 5em;
    padding-left: 0;

    @include mq(small) {
      flex-direction: row;
      padding-top: inherit;
      height: 100%;

      &-item {
        height: 100%;
      }
    }
  }

  &__sub-menu {
    display: none;
  }

  &__link {
    color: var(--clr-white);
    text-decoration: none;
    font-size: var(--fs-600);
    transition: background-color ease 0.2s;
    @include mq(small) {
      font-size: var(--fs-500);
      height: 100%;
      display: flex;
      align-items: center;
      padding: 1.875rem;
    }

    &:hover,
    &.active {
      text-decoration: none;
      color: var(--clr-white);

      // @include mq(small) {
      background-color: #08214e;
      // }
    }
  }
}
