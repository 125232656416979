.input {
  display: flex;
  flex-direction: column;

  &__label {
    color: var(--clr-primary);
    font-size: 1.125rem;
    user-select: none;
  }

  &__element {
    border: none;
    border-radius: 15px;
    font-size: var(--fs-450);
    padding: 1rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--clr-primary);
    color: var(--clr-primary);

    &:focus {
      outline: 2px solid var(--clr-primary);
    }

    &::-webkit-calendar-picker-indicator {
      color: var(--clr-primary);
    }
  }
}
