.divider {
  --bg-color: linear-gradient(
    to right,
    var(--clr-green-energy),
    var(--clr-warm-sun)
  );

  height: 6px;
  background: var(--bg-color);
}
