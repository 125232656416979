.checkbox {
  display: flex;
  align-items: center;

  &--baseline {
    align-items: flex-start;
  }

  &__root {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid var(--clr-primary);
    cursor: pointer;
    flex-shrink: 0;

    &:hover {
      //background-color: rgba(c.$purple, 0.2);
    }

    &:focus-visible {
      box-shadow: 0 0 0 6px hsl(var(--clr-neutral-800));
    }
  }

  &__indicator {
    color: var(--clr-primary);
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__label {
    color: var(--clr-primary);
    padding: 0 0 0 0.5em;
    font-size: clamp(1.125rem, 4vw, 1.5rem);
    line-height: 1.4;
    cursor: pointer;
    user-select: none;
    font-weight: 400;

    &--small {
      font-size: clamp(1rem, 4vw, 1.5rem);
    }

    a {
      color: inherit;
      font-weight: 600;
    }
  }
}

.checkboxes.fieldset .fieldset__legend {
  text-align: left;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 600;
  margin-block-end: 0.5rem;
}

.checkboxes {
  &__list {
    padding: 0 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3rem;
  }
}
