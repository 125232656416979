.bonuswelt_company {
  border: 3px solid var(--clr-primary);
  border-radius: var(--border-radius, 15px);
  height: 100%;

  &__logo {
    grid-area: Logo;
    display: initial;
    height: 100px;
    margin: auto;
    width: 100%;
    position: relative;

    span {
      width: 100%;
      height: auto;
    }

    img {
      border-radius: 0px;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    margin-bottom: -50px;
    img {
      border-radius: 11px 11px 0px 0px;
    }
  }
  &__container {
    height: auto;
    width: 90%;
    margin: auto;
    border-radius: 15px 15px 0px 0px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px 0px;
    grid-template-rows: 7fr auto auto 5fr;
    grid-template-areas:
      'Logo'
      'Name'
      'Text'
      'Link';
    min-height: 450px;
    background-color: var(--clr-white);
    position: relative;
    align-items: center;

    .bonuswelt__button {
      // margin-bottom: 15px;
      // margin-top: 15px;
      font-size: var(--fs-400);
      grid-area: Link;
      text-align: center;

      a {
        border-radius: 15px;
        border: 3px solid var(--clr-primary);
        text-decoration: none;
        padding: 10px 15px;
        display: inline-block;
      }
    }
  }

  &__name {
    text-align: center;
    // margin-bottom: 30px;
    grid-area: Name;
    word-break: break-word;
  }
  &__description {
    color: var(--clr-dark-grey);
    grid-area: Text;
    font-size: var(--fs-400);
    text-align: center;
  }
}
