@use '../abstracts/mixins' as *;

.bonuswelt-detail-page {
  color: var(--clr-dark-grey);

  .banner {
    margin-bottom: 0px;
    @include mq(big) {
      margin-bottom: -200px;
    }
  }
  .container {
    margin: auto;
    position: relative;
    background: var(--clr-white);
    border-radius: var(--border-radius, 15px);
    padding-left: 0;
    padding-right: 0;

    @include mq(medium) {
      margin-bottom: calc(2 * var(--spacer));
    }
  }

  .company__primary-info {
    max-width: 913px;
    margin: 0 auto;
  }
  h1 {
    padding: 40px 0 80px;
    text-align: center;
  }
  hr {
    width: 100%;
    border: 1px solid var(--clr-primary);
    margin-top: 40px;
    margin-bottom: var(--spacer);
  }

  .company__category {
    &__label {
      font-size: var(--fs-400);
      color: var(--clr-dark-grey);
    }
  }

  .company__secondary-info {
    @include mq(medium) {
      padding-left: 9.375rem;
    }
  }

  .split-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--spacer);
    row-gap: var(--spacer);

    @include mq(medium) {
      flex-direction: row;
      align-items: stretch;

      > div {
        width: 100%;
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 50px;
      font-weight: normal;

      @include mq(medium) {
        text-align: left;
      }
    }

    &__element {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @include mq(medium) {
        justify-content: space-between;
      }
    }
  }
  .company__description {
    margin-bottom: 20px;
  }
  .company__name {
    font-size: var(--fs-500);
    font-weight: bold;
  }
  .company__logo__container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: var(--spacer);
    .company__logo {
      max-width: 400px;
      max-height: 250px;
    }
  }
  .company__details {
    margin-top: 25px 0;
    font-size: 1.5rem;

    @include mq(medium) {
      margin-bottom: 3rem;
    }

    a {
      color: var(--clr-dark-grey);
      text-decoration: none;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }
  .bonuswelt-uebersicht-link {
    text-align: center;
    margin-bottom: var(--spacer);

    a {
      color: var(--clr-primary);
      background-color: var(--clr-white);
      text-decoration: none;
      display: inline-block;
      font-size: var(--fs-400);
    }
  }
  .bonuswelt-map-container {
    max-width: 520px;
    width: 100%;
    @include mq(medium) {
      margin-top: 0px;
    }
    .bonuswelt-map {
      max-width: 520px;
    }
  }
  .company__category-item {
    white-space: nowrap;
    background-color: var(--clr-glowing-blue);
    color: var(--clr-white);
    border-radius: var(--border-radius);
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 6px;
    font-size: var(--fs-400);
    margin: 0 5px;
  }
}
